<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Депозиты</h3>
    </div>

    <div>
      <v-data-table
          :items="deposit"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc
          show-expand

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }"> {{ item.status }}</template>

        <template v-slot:item.amount_proposed="{ item }">  {{ `${item.amount_proposed.toLocaleString('ru-RU')}` }}
         {{ item.deposit_wallet.crypto_network.crypto.title}}
        </template>

        <template v-slot:item.network="{ item }">
          {{ item.deposit_wallet ? item.deposit_wallet.crypto_network.title : "" }}
        </template>

        <template v-slot:item.description="{ item }"> {{ item.deposit_wallet.description }}</template>

        <template v-slot:item.walletid="{ item }"> {{ item.deposit_wallet ? item.deposit_wallet.id : "--" }}</template>

        <template v-slot:item.created_at="{ item }"> {{
            new Date(Date.parse(item.created_at)).toLocaleString()
          }}
        </template>
        <template v-slot:item.check="{ item }"><a v-if="item.check" :href="item.check">Чек</a></template>


        <template v-slot:item.actions="{ item }">


          <NumberEditDialog caption="Пополнить" title="Нужно ввести сумму, которую перевёл пользователь"
                            label="Сумма" :tag="item"
                            value="0" v-on:ok="success"></NumberEditDialog>

          <v-btn class="red" dense text @click="cancel(item)">Отклонить</v-btn>

          <!--TextInputDialog  buttonclass="red" buttontext="Отклонить" caption="Причина" :tag="item" v-on:ok="cancel"></TextInputDialog-->
        </template>

        <template v-slot:item.nickname="{ item }"> {{ item.history?.user ? item.history.user.nickname : "" }}</template>
        <template v-slot:item.userid="{ item }"> {{ item.history?.user ? item.history.user.id : "" }}</template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length-2">

            <span> {{ `Валюта: ${item.deposit_wallet.crypto_network.crypto.title}` }} </span><br>
            <span> {{
                `Баланс после: ${item.balance_now.toLocaleString('ru-RU', {
                  style: 'currency',
                  currency: 'USD'
                })}`
              }} </span><br>
            <span> {{
                `Баланс до: ${item.balance_old.toLocaleString('ru-RU', {
                  style: 'currency',
                  currency: 'USD'
                })}`
              }} </span><br>
            <span> {{ `ID пользователя: ${item.history.user ? item.history.user.id : ""}` }} </span><br>
            <span> {{ `Кошелек зачисления:  ${item.deposit_wallet ? item.deposit_wallet.wallet : "--"}` }} </span><br>
            <span> {{ `Время изменения: ${new Date(Date.parse(item.created_at)).toLocaleString()}` }} </span><br>

          </td>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";
import Snackbar from "../components/controls/Snackbar.vue";
//import TextInputDialog from "../components/dialogs/TextInputDialog.vue";
import {fetcher, getHeaders} from "../store";
import NumberEditDialog from "../components/dialogs/NumberEditDialog.vue";


export default {
  name: 'ExchangeDeposits',

  components: {Snackbar, NumberEditDialog},

  apollo: {
    $subscribe: {
      deposit: {
        query: gql`

 subscription MyQuery {
  deposit(where: {status: {_in: ["В процессе","Подтвержден"]}}, order_by: {id: desc}) {
    id
    action
    balance_now
    balance_old
    created_at
    status
    updated_at

    history {
      user {
        id
        nickname
      }
    }
    check
    amount_proposed
    amount_fact
    deposit_wallet {
      id
      wallet
      description
      crypto_network {
        title
        crypto {
          title
        }
      }
    }
  }
}



        `,
        result({data}) {
          this.deposit = data.deposit;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',
    deposit: [],

    headers: [

      {value: "id", text: "ID"},
      {value: "status", text: "Статус"},

      {value: "amount_proposed", text: "Cумма предл."},
      //{ value: "amount_fact", text: "Cумма зачис." },

      {value: "network", text: "Сеть"},
      {value: "description", text: "Комент"},


      {value: "nickname", text: "Ник юзера"},

      {value: "walletid", text: "ID кошелька"},

      {value: "created_at", text: "Время создания"},

      {value: "check", text: "Чек"},

      {value: "actions", text: "Действия"},


    ],
  }),

  methods: {

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async success(tag, value) {
      //  /conclusion/success/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({amount: value})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/deposit/success/${tag.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async cancel(value) {
      //  /conclusion/cancel/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        // body: JSON.stringify({error_text : text})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/deposit/cancel/${value.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    amount_usdt(item) {
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_usdt_old - item.conversions[0].balance_usdt_now) : 0
    },

    amount_btc(item) {
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_btc_old - item.conversions[0].balance_btc_now) : 0
    },

    money_from(item) {
      if (item.conversions) {
        if (item.conversions[0].convert_to === "USDT")   //from btc
          return this.amount_btc(item) + " BTC"
        else
          return this.amount_usdt(item) + " USDT"
      } else
        return "0"
    },

    money_to(item) {
      if (item.conversions) {
        if (item.conversions[0].convert_to === "USDT") //from usdt
          return this.amount_usdt(item) + " USDT"
        else
          return this.amount_btc(item) + " BTC"
      } else
        return "0"
    },

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

  },

}
</script>
